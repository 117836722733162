p {
  margin: 0 0 (@line-height-computed / 2);
}

.lead {
  margin-bottom: @line-height-computed;
  font-size: floor((@font-size-base * 1.15));
  font-weight: 300;
  line-height: 1.4;

  @media (min-width: @screen-sm-min) {
    font-size: (@font-size-base * 1.5);
  }
}

small,
.small {
  font-size: floor((100% * @font-size-small / @font-size-base));
}

.text-left           { text-align: left; }
.text-right          { text-align: right; }
.text-center         { text-align: center; }
.text-justify        { text-align: justify; }
.text-nowrap         { white-space: nowrap; }


.text-muted {
  color: @text-muted;
}
.text-primary {
  .text-emphasis-variant(@brand-primary);
}
.text-success {
  .text-emphasis-variant(@state-success-text);
}
.text-info {
  .text-emphasis-variant(@state-info-text);
}
.text-warning {
  .text-emphasis-variant(@state-warning-text);
}
.text-danger {
  .text-emphasis-variant(@state-danger-text);
}

.bg-primary {
  // Given the contrast here, this is the only class to have its color inverted
  // automatically.
  color: #fff;
  .bg-variant(@brand-primary);
}
.bg-success {
  .bg-variant(@state-success-bg);
}
.bg-info {
  .bg-variant(@state-info-bg);
}
.bg-warning {
  .bg-variant(@state-warning-bg);
}
.bg-danger {
  .bg-variant(@state-danger-bg);
}

ul,
ol {
  margin-top: 0;
  margin-bottom: (@line-height-computed / 2);
  ul,
  ol {
    margin-bottom: 0;
  }
}


.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  .list-unstyled();
  margin-left: -5px;

  > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }
}

dl {
  margin-top: 0; // Remove browser default
  margin-bottom: @line-height-computed;
}
dt,
dd {
  line-height: @line-height-base;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0; // Undo browser default
}


// Blockquotes
blockquote {
  padding: (@line-height-computed / 2) @line-height-computed;
  margin: 0 0 @line-height-computed;
  font-size: @blockquote-font-size;
  border-left: 5px solid @blockquote-border-color;

  p,
  ul,
  ol {
    &:last-child {
      margin-bottom: 0;
    }
  }

  footer,
  small,
  .small {
    display: block;
    font-size: 80%; // back to default font-size
    line-height: @line-height-base;
    color: @blockquote-small-color;

    &:before {
      content: '\2014 \00A0'; // em dash, nbsp
    }
  }
}

.blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid @blockquote-border-color;
  border-left: 0;
  text-align: right;

  // Account for citation
  footer,
  small,
  .small {
    &:before { content: ''; }
    &:after {
      content: '\00A0 \2014'; // nbsp, em dash
    }
  }
}

address {
  margin-bottom: @line-height-computed;
  font-style: normal;
  line-height: @line-height-base;
}
