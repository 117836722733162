//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  list-style: none;

  > li {
    display: inline-block;

    + li:before {
      content: "@{breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: @breadcrumb-color;
    }
  }

  > .active {
    color: @breadcrumb-active-color;
  }
}
