//
// Close icons
// --------------------------------------------------


.close {
  float: right;
  line-height: 1;
  .opacity(.2);

  &:hover,
  &:focus {
    text-decoration: none;
    cursor: pointer;
    .opacity(.5);
  }

  // Additional properties for button version
  // iOS requires the button element instead of an anchor tag.
  // If you want the anchor version, it requires `href="#"`.
  // See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
  button& {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
  }
}
